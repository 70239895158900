import { library } from '@fortawesome/fontawesome-svg-core'

import {
    faCaretUp,
    faCaretDown,
    faCaretRight,
    faExclamationTriangle,
    faCheckCircle,
    faCheck,
    faTimes,
    faBan,
    faEdit,
    faInfoCircle,
    faPhone,
    faCalendar,
    faEnvelope,
    faPlusSquare,
    faPhoneVolume,
    faPhoneSlash,
    faEnvelopeCircleCheck,
    faHandshake,
    faArrowRotateBack,
    faCalendarPlus,
    faPlus,
    faClock,
    faEye,
    faFlag,
    faSpinner,
    faStar,
    faArrowRight,
    faReply,
} from '@fortawesome/free-solid-svg-icons'

// import 
/* <font-awesome-icon :icon="['fas', 'trash-alt']" />
                        <font-awesome-icon :icon="['fas', 'edit']" />
                        <font-awesome-icon :icon="['fas', 'circle-xmark']" /> */
import {
    faTrashAlt,
    faEdit,
    faCircleXmark,
    faCircle
} from '@fortawesome/free-solid-svg-icons'

import { faUndo, faUserCheck } from '@fortawesome/free-solid-svg-icons';


library.add(
    faCaretUp,
    faCaretDown,
    faCaretRight,
    faExclamationTriangle,
    faCheckCircle,
    faCheck,
    faTimes,
    faBan,
    faEdit,
    faInfoCircle,
    faPhone,
    faCalendar,
    faCalendarPlus,
    faEnvelope,
    faEnvelopeCircleCheck,
    faPlus,
    faPlusSquare,
    faPhoneVolume,
    faPhoneSlash,
    faHandshake,
    faArrowRotateBack,
    faTrashAlt,
    faEdit,
    faCircleXmark,
    faCircle,
    faClock,
    faEye,
    faSpinner,
    faStar,
    faArrowRight,
    faCheck,
    faFlag,
    faReply
)

library.add(faCircleXmark, faCheckCircle, faUndo, faUserCheck);
