<template>
    <div class="flex justify-between mb-2" :class="$attrs.class">
        <input type="hidden" name="data_transfer_accepted" :value="0">

        <input type="checkbox" name="data_transfer_accepted" id="data_transfer_accepted"
            class="block cursor-pointer accent-blue-500" :value="1" :checked="lead.data_transfer_accepted"
            v-model="lead.data_transfer_accepted" @change="updateIsDataTransferAcceptedState">

        <label for="data_transfer_accepted" class="ml-3 font-semibold text-gray-700 select-none cursor-pointer">Der
            Interessent ist mit der Weitergabe seiner persönlichen Daten einverstanden.</label>
    </div>

    <template v-for="sector in sectors" :key="sector.id">
        <div v-if="sectors.length > 1"
            class="flex justify-between items-center mt-5 border-b border-gray-300 py-2 px-1">
            <div class="flex items-center cursor-pointer font-semibold text-gray-800" @click="toggleSector(sector.id)">
                <font-awesome-icon class="mr-2 transform transition-transform duration-300"
                    :class="{ 'rotate-90': expandedSector === sector.id }" :icon="['fa', 'caret-right']" />
                <span v-html="sector.name"></span>
            </div>
        </div>

        <table v-if="expandedSector === sector.id"
            class="w-full mt-3 border border-gray-200 rounded-lg shadow-sm text-sm ">
            <thead>
                <tr class="bg-blue-100">
                    <th class="py-2 px-3 text-left font-semibold text-gray-600">Software</th>
                    <th class="py-2 px-3 text-left font-semibold text-gray-600">{{ new Date().toLocaleString('de-DE',
                        { month: 'long' }) }} Leads</th>
                    <th class="py-2 px-3 text-left font-semibold text-gray-600">Status</th>
                    <th class="py-2 px-3 text-left font-semibold text-gray-600"></th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="vendor in sector.vendors" :key="vendor.id" :class="{ 'opacity-40': vendor.isRejected }"
                    class="bg-white even:bg-gray-50 hover:bg-gray-100">
                    <td class="py-2 px-2" style="max-width: 25px;">
                        <div class="flex items-center">
                            <span v-html="vendor.name ?? vendor.software_name ?? vendor.vendor_name"></span>
                            <div v-if="!isEmpty(vendor.info_leads)" class="relative group ml-2">
                                <font-awesome-icon class="text-gray-500" :icon="'info-circle'" />
                                <div
                                    class="absolute hidden group-hover:block bg-white text-left shadow-lg p-2 mt-2 border border-gray-300 z-10">
                                    <p v-html="vendor.info_leads" class="whitespace-pre-wrap"></p>
                                </div>
                            </div>
                        </div>
                        <!-- Hidden ticket input fields -->
                        <template v-if="shouldTicketBeUpdated(vendor.id)">
                            <input type="hidden" :name="`tickets[${vendor.id}][id]`" :value="getTicket(vendor.id).id">
                            <input type="hidden" :name="`tickets[${vendor.id}][vendor_id]`" :value="vendor.id">
                            <!-- TODO: rename to status -->
                            <input type="hidden" :name="`tickets[${vendor.id}][state]`"
                                :value="getTicketStatus(vendor.id)">
                            <input type="hidden" :name="`tickets[${vendor.id}][allocated_at]`"
                                :value="getTicket(vendor.id).allocated_at">
                            <input type="hidden" :name="`tickets[${vendor.id}][accepted_at]`"
                                :value="getTicket(vendor.id).accepted_at">
                            <input type="hidden" :name="`tickets[${vendor.id}][denied_at]`"
                                :value="getTicket(vendor.id).denied_at">
                            <input type="hidden" :name="`tickets[${vendor.id}][has_changed]`"
                                :value="getTicket(vendor.id).has_changed">
                        </template>
                    </td>
                    <td class="py-2 px-1 text-center">
                        <span v-if="isUndefined(vendor.users_exists) || vendor.users_exists"
                            v-html="vendor.tickets_count"></span>
                    </td>
                    <td class="py-2 px-3">
                        <div class="flex items-center">
                            <div :class="getStatusColor(vendor.id)" class="w-3 h-3 rounded-full mr-2"></div>
                            <span :class="{ 'text-orange-500': getStatusMessage(vendor.id) === 'kostenfreie Leads' }"
                                v-html="getStatusMessage(vendor.id)"></span>
                        </div>
                    </td>
                    <td>
                        <!-- Action buttons in the first row -->
                        <div class="flex flex-col gap-2 mb-4 mt-4 p-2 align-middle items-center">
                            <!-- Added margin for separation -->
                            <button
                                v-if="isTicketStatus(vendor.id, TicketStatuses.Considered) || isTicketStatus(vendor.id, TicketStatuses.Denied) || isTicketStatus(vendor.id, TicketStatuses.Pending)"
                                :disabled="!lead.data_transfer_accepted || vendor.isRejected"
                                @click.prevent="setTicketStatus(vendor, TicketStatuses.Pending)"
                                class="lmt-btn-xs lmt-btn-success"
                                style="font-size: 0.75rem; padding: 0.375rem 0.75rem; min-width: 120px;">
                                <!-- Adjust min-width as needed -->
                                Zuweisen
                            </button>
                            <button v-if="isTicketStatus(vendor.id, TicketStatuses.Accepted)"
                                :disabled="!lead.data_transfer_accepted || vendor.isRejected"
                                @click.prevent="setTicketStatus(vendor, TicketStatuses.Denied)"
                                class="lmt-btn-xs lmt-btn-danger"
                                style="font-size: 0.75rem; padding: 0.375rem 0.75rem; min-width: 120px;">
                                <!-- Adjust min-width as needed -->
                                Zurücknehmen
                            </button>

                            <SendEmail
                                v-if="getStatusMessage(vendor.id) === 'kostenfreie Leads' || getStatusMessage(vendor.id) === 'Kein Vertrag'"
                                class="lmt-btn-xs lmt-btn-success" :email-template="this.epostBlaupause"
                                :lead-id="lead.id" :lead="lead" :modal-title="'E-Mail an ' + vendor.name + ' senden'"
                                :mail-to="vendor.email" :for-vendor="true" style="min-width: 120px;">
                                <!-- Adjust min-width as needed -->
                            </SendEmail>
                        </div>


                        <!-- Icon links in the second row -->
                        <div class="flex gap-2 items-center align-middle">
                            <a v-if="!vendor.isRejected" :href="vendor.calendar_url"
                                class="text-blue-500 hover:text-blue-700">
                                <font-awesome-icon :icon="'calendar'" />
                            </a>
                            <a v-if="!vendor.isRejected" :href="vendor.onboarding_url"
                                class="text-blue-500 hover:text-blue-700">
                                <font-awesome-icon :icon="'calendar-plus'" />
                            </a>
                            <button v-if="!vendor.isRejected" @click.prevent="rejectVendor(vendor)"
                                class="text-red-500 hover:text-red-700">
                                <font-awesome-icon :icon="'ban'" />
                            </button>
                            <button v-if="vendor.isRejected" @click.prevent="restoreVendor(vendor)"
                                class="text-green-500 hover:text-green-700">
                                <font-awesome-icon :icon="'arrow-rotate-back'" />
                            </button>
                        </div>
                    </td>

                </tr>
            </tbody>
        </table>
    </template>
</template>


<script lang="ts">

import { defineComponent } from "vue";

import { Store, useStore } from "vuex";
import { key, RootStoreInterface } from "../../Store";
import { LeadMap } from "../../Store/Lead";

import { Lead, Sector, Ticket, Vendor } from "../../typings/types"
import { SendEmail } from "@/components/Leads/SendEmail";
import SendEmail from "./SendEmail.vue";


enum TicketStatuses {
    NoContract = 'no_contract',
    ContractExpired = 'contract_expired',
    Considered = 'considered',
    Pending = 'pending',
    Accepted = 'accepted',
    Returned = 'returned',
    Denied = 'denied',
    CustomerClosing = 'customer_closing',
    FreeLead = 'free_lead'
}

const TicketColors = {
    [TicketStatuses.NoContract]: 'bg-gray-300 text-orange',
    [TicketStatuses.ContractExpired]: 'bg-gray-300',
    [TicketStatuses.Considered]: 'bg-blue-400',
    [TicketStatuses.Pending]: 'lmt-bg-main-blue',
    [TicketStatuses.Accepted]: 'bg-purple-800',
    [TicketStatuses.Returned]: 'bg-red-700',
    [TicketStatuses.Denied]: 'bg-red-400',
    [TicketStatuses.CustomerClosing]: 'bg-green-500',
    // freeleads should have an orange dot
    [TicketStatuses.FreeLead]: 'bg-orange-500'
}

const TicketMessages = {
    [TicketStatuses.NoContract]: 'Kein Vertrag',
    [TicketStatuses.ContractExpired]: 'Vertrag abgelaufen',
    [TicketStatuses.Considered]: 'In Betracht',
    [TicketStatuses.Pending]: 'Ausstehend',
    [TicketStatuses.Accepted]: 'Zugewiesen',
    [TicketStatuses.Returned]: 'Abgelehnt',
    [TicketStatuses.Denied]: 'Zurückgenommen',
    [TicketStatuses.CustomerClosing]: 'Abgeschlossen',
    [TicketStatuses.FreeLead]: 'kostenfreie Leads'
}

type LeadAllocationData = {
    TicketStatuses: Object,
    lead: Lead,
    expandedSector: number | null,
    store: Store<RootStoreInterface>
}


export default defineComponent({
    components: { SendEmail },
    props: {
        initialLead: {
            type: Object as () => Lead,
            required: true
        },
    },

    data() {
        return {
            TicketStatuses,
            lead: this.initialLead,
            expandedSector: null,
            store: useStore(key),
            epostBlaupause: '<p>Lieber Anbieter,</p><br><p>wir sind von Systemhaus.com und möchten Ihnen dieses Lead schenken. Wir überlassen Ihnen die Daten freiwillig um unsere Bereitschaft zur Zusammenarbeit zu zeigen. Wir würden uns über eine Antwort sehr freuen, wenn wir Ihnen auch unseren Vertriebsservice anbieten dürften, der direkt mit einem Lead spricht und einen Folgetermin mit Ihrem Support macht oder direkt den Kunden die Software zeigen kann. Wir sind offen für provisionierte Vorschläge, aber testen Sie in Ruhe erstmal. Wir danken Ihnen sehr.</p><br><p>Herzliche Grüße,</p><p>Ihr Team von Systemhaus.com</p>',
        }
    },

    mounted() {
        console.log("mounted", this.lead)
        this.store.commit(LeadMap.mutations.setIsDataTransferAccepted, this.lead.data_transfer_accepted)

        if (this.lead.tickets === undefined) {
            this.lead.tickets = []
        }
        console.log("mounted", this.lead)

        // console log leaddata
        const lead_data = JSON.parse(JSON.stringify(this.lead))
        console.log(lead_data)
        // extract user data and sectors[0].wizard_questions
        const user_data = lead_data.user
        const wizard_questions = lead_data.sectors[0].wizard_questions
        const payload = {
            user_data,
            wizard_questions
        }
        console.log(payload)
    },

    computed: {
        sectors: function (): Sector {
            let sectors = this.store.getters[LeadMap.getters.getSectors]

            if (this.isEmpty(sectors)) {
                return sectors
            }

            this.expandedSector = sectors.at(-1).id

            return sectors
        },
        console: () => console,
        window: () => window,
    },

    methods: {
        toggleSector(sectorId) {
            this.expandedSector = this.expandedSector === sectorId ? null : sectorId
        },

        shouldTicketBeUpdated(vendorId: number): boolean {
            const ticketStatus = this.getTicketStatus(vendorId)

            return ticketStatus === TicketStatuses.Pending || ticketStatus === TicketStatuses.Denied || ticketStatus === TicketStatuses.Accepted
        },

        setTicketStatus(vendor: Vendor, ticketStatus: TicketStatuses) {
            let ticket = this.getTicket(vendor.id)
            console.log("setTicketStatus", vendor, ticketStatus, ticket)

            let allocatedAt = !this.isNull(ticket?.allocated_at) ? ticket.allocated_at : null
            let acceptedAt = !this.isNull(ticket?.accepted_at) ? ticket.accepted_at : null
            let deniedAt = !this.isNull(ticket?.denied_at) ? ticket.denied_at : null

            if (ticketStatus === TicketStatuses.Denied) {
                deniedAt = (new Date()).toISOString()
            }

            if (ticketStatus === TicketStatuses.Pending) {
                allocatedAt = (new Date()).toISOString()
            }

            if (ticketStatus === TicketStatuses.Pending && vendor.current_contract?.auto_lead_delivery) {
                ticketStatus = TicketStatuses.Accepted
                acceptedAt = allocatedAt
            }

            if (ticketStatus === TicketStatuses.Denied && this.isNull(ticket.id)) {
                this.lead.tickets = this.lead.tickets.filter(ticket => ticket.vendor_id !== vendor.id)

                return
            }

            if (this.isUndefined(ticket)) {
                this.lead.tickets.push({
                    lead_id: this.lead.id,
                    vendor_id: vendor.id,
                    status: ticketStatus,
                    allocated_at: allocatedAt,
                    accepted_at: acceptedAt,
                    denied_at: deniedAt,
                    has_changed: true
                });

                return
            }

            ticket.status = ticketStatus
            ticket.allocated_at = allocatedAt
            ticket.accepted_at = acceptedAt
            ticket.denied_at = deniedAt
            ticket.has_changed = true
        },

        getTicket(vendorId: number): Ticket | null {
            return this.lead.tickets.find((ticket: Ticket) => ticket.vendor_id === vendorId)
        },

        getTicketStatus(vendorId: number): TicketStatuses {
            let vendor;

            this.sectors.some((sector: Sector) => {
                vendor = sector.vendors.find((vendor: Vendor) => vendor.id === vendorId)

                if (!this.isUndefined(vendor)) {
                    return true;
                }
            })

            if (this.isUndefined(vendor)) {
                return
            }

            if (!this.isUndefined(vendor.users_exists) && !vendor.users_exists) {
                return
            }

            // if vendor.freelead == 1, return TicketStatuses.FreeLead
            if (vendor.freelead == 1) {
                return TicketStatuses.FreeLead
            }

            if (!vendor.contracts_exists) {
                return TicketStatuses.NoContract
            }

            if (vendor.contracts_exists && this.isNull(vendor.current_contract)) {
                return TicketStatuses.ContractExpired
            }

            let ticket = this.getTicket(vendorId)

            return ticket?.status ?? TicketStatuses.Considered
        },

        getStatusMessage(vendorId: number): string {
            const status = this.getTicketStatus(vendorId)

            return TicketMessages[status]
        },

        getStatusColor(vendorId: number): string {
            const status = this.getTicketStatus(vendorId)

            return TicketColors[status]
        },

        isTicketStatus(vendorId: number, ticketStatus: TicketStatuses): boolean {
            return this.getTicketStatus(vendorId) === ticketStatus
        },

        rejectVendor(vendor: Vendor) {
            vendor.isRejected = true
        },

        restoreVendor(vendor: Vendor) {
            vendor.isRejected = false
        },

        updateIsDataTransferAcceptedState() {
            this.store.commit(LeadMap.mutations.setIsDataTransferAccepted, this.lead.data_transfer_accepted)
        },

        disabledClasses(condition: boolean): string {
            return condition ? 'opacity-50 cursor-not-allowed' : ''
        },

        askMail(vendor: Vendor) {
            // ask user for destination email and use vendor.flexible_field as default
            const email = prompt('Ist die E-Mail Adresse korrekt?', vendor.flexible_field)
            // if email is not null, set vendor.flexible_field to email

            if (!email) {
                return
            }


            if (email) {
                vendor.flexible_field = email
            }

            // if cancel, do nothing


            // prepare data for log
            const lead_data = JSON.parse(JSON.stringify(this.lead))
            /*
            * needed:
            * lead {
            *   id, full_name, company_name, email, phone, street, zip, city, message
            * },
            * wizard_questions {
            *   id, question, answer where answer is inside lead_answer.answer
            * }
            * */

            // extract user data and sectors[0].wizard_questions
            const user_data = lead_data.user
            const wizard_questions = lead_data.sectors[0].wizard_questions
            const payload = {
                user_data,
                wizard_questions,
                msg: lead_data.message,
                send_to: email
            }

            // construct "view(email.newVendor, payload)"
            // send this to PUT /leads/:leadid/sanae
            // also add csrf token
            // log response
            const url = `/newven/snae`
            fetch(url, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
                },
                body: JSON.stringify(payload)
            })
                // response will be a html page
                .then(response => response.text())
                .then(data => {
                    // set status to TicketStatuses.Accepted
                    this.setTicketStatus(vendor, TicketStatuses.Accepted)
                    console.log("set status to TicketStatuses.Accepted", vendor)
                })
                .catch(error => console.log(error))

        }
    }
})

</script>
