<template>
    <!-- Modal background overlay -->
    <div class="fixed inset-0 bg-gray-900 bg-opacity-50 flex justify-center items-center z-50">
        <!-- Modal box -->
        <div class="bg-white rounded-lg shadow-lg max-w-full w-full mx-4 p-6 relative z-50 overflow-y-auto">
            <!-- Close button -->
            <button @click="$emit('close-modal')" class="absolute top-2 right-2 text-gray-600 hover:text-gray-900">
                <font-awesome-icon :icon="['fas', 'times']"></font-awesome-icon>
            </button>

            <div class="border-b border-gray-300 pb-4 mb-4">
                <h2
                    class="text-xl font-semibold text-gray-800 flex-1 min-w-0 lmt-bg-main-blue  text-white px-4 py-2 rounded-md">
                    Lead #{{ ticket.id }} ansehen
                </h2>
                <p class="text-gray-600 flex-1 min-w-0">
                    <span class="font-semibold">Eingegangen:</span> {{ Intl.DateTimeFormat('de-DE', {
                        dateStyle: 'full',
                        timeStyle: 'short'
                    }).format(new Date(ticket.lead.created_at)) }}
                    <br>
                    <span class="font-semibold">Lead Status:</span> {{ ticket.lead.stateNicename }}
                </p>
                <p class="text-gray-600 flex-1 min-w-0">
                    <span class="font-semibold">Leadqualität:</span> {{ ticket.lead.lead_quality }}
                </p>
            </div>

            <div class="grid grid-cols-1 md:grid-cols-3 gap-4">
                <!-- Lead user details -->
                <div class="w-full md:w-1/2 px-2 mb-4">
                    <h3 class="text-lg font-semibold mb-2 text-gray-800">Kundeninformationen</h3>
                    <p class="text-gray-600">
                        <span class="font-semibold">Name:</span> {{ ticketCreator.first_name }} {{
                            ticketCreator.last_name }}
                        <br>
                        <span class="font-semibold">E-Mail:</span> {{ ticketCreator.email }}
                        <br>
                        <span class="font-semibold">Telefon:</span> {{ ticketCreator.phone }}
                    <div class="mt-2 space-y-2">
                        <div class="flex items-center">
                            <input type="radio" id="was_reached_by_phone" name="phone_status"
                                class="text-blue-600 focus:ring-blue-500 focus:ring-2">
                            <label for="was_reached_by_phone" class="ml-2 text-gray-800">Erreicht</label>
                        </div>

                        <div class="flex items-center">
                            <input type="radio" id="is_working_phone_number" name="phone_status"
                                class="text-blue-600 focus:ring-blue-500 focus:ring-2">
                            <label for="is_working_phone_number" class="ml-2 text-gray-800">Korrekt, aber nicht
                                erreicht</label>
                        </div>

                        <div class="flex items-center">
                            <input type="radio" id="is_wrong_phone_number" name="phone_status"
                                class="text-blue-600 focus:ring-blue-500 focus:ring-2">
                            <label for="is_wrong_phone_number" class="ml-2 text-gray-800">Falsch</label>
                        </div>
                    </div>

                    </p>
                </div>

                <!-- Address of ticketCreator -->
                <div class="w-full md:w-1/2 px-2 mb-4">
                    <h3 class="text-lg font-semibold mb-2 text-gray-800">Adresse</h3>
                    <p class="text-gray-600">
                        <span class="font-semibold">Straße:</span> {{ ticketCreator.street }}
                        <br>
                        <span class="font-semibold">PLZ:</span> {{ ticketCreator.zip }}
                        <br>
                        <span class="font-semibold">Ort:</span> {{ ticketCreator.city }}
                    </p>
                </div>

                <!-- Hinweise für Leadqualifizierer -->
                <div class="w-full px-2 mb-4">
                    <h3 class="text-lg font-semibold mb-2 text-gray-800">Hinweise für Leadqualifizierer</h3>
                    <textarea class="w-full h-24 p-2 bg-gray-100 rounded-md"
                        readonly>{{ ticket.lead.lead_qualifier }}</textarea>
                </div>
            </div>

            <hr class="my-4 border-gray-300">
            <!-- Lead Answers -->
            <div class="mb-4">
                <h3 class="text-lg font-semibold mb-2 text-gray-800">Wizardantworten</h3>
                <div class="grid grid-cols-1 md:grid-cols-4 gap-4">
                    <template v-for="(dataEntry, dataIndex) in wizardAnswers" :key="'entry-' + dataIndex">
                        <div class="p-4 bg-gray-100 rounded-lg shadow">
                            <h4 class="text-base font-semibold mb-2 text-gray-800">{{ dataEntry.question.question }}
                            </h4>
                            <div v-for="(answer, answerIndex) in dataEntry.question.lead_answers"
                                :key="'answer-' + answerIndex">
                                <input type="text" class="w-full p-2 bg-gray-200 rounded-md" readonly
                                    :value="answer.answer">
                            </div>
                        </div>
                    </template>
                </div>
            </div>

            <hr class="my-4 border-gray-300">
            <!-- Lead external memo -->
            <h3 class="text-lg font-semibold mb-2 text-gray-800">Externe Notizen</h3>
            <textarea class="w-full h-24 p-2 bg-gray-100 rounded-md" readonly>{{ ticket.lead.external_memo }}</textarea>

            <!-- Action buttons -->
            <div class="flex justify-end space-x-4">
                <button @click="$emit('close-modal')"
                    class="bg-gray-300 text-gray-800 px-4 py-2 rounded-md hover:bg-gray-400">
                    Cancel
                </button>
                <button class="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600">
                    Confirm
                </button>
            </div>
        </div>
    </div>
</template>



<script lang="ts">
import { isProxy, toRaw } from 'vue';

interface LeadAnswer {
    answer: string | null;
}

interface QuestionData {
    id: number;
    lead_answers: LeadAnswer[];
    question: string;
}

interface DataEntry {
    question: QuestionData;
}

// Array of DataEntry
type WizardAnswer = DataEntry[];


export default {
    name: 'Modal',
    props: {
        show: {
            type: Boolean,
            required: true
        },
        title: {
            type: String,
            default: 'Modal Title'
        },
        message: {
            type: String,
            default: 'This is a simple modal, now toggleable from a parent component.'
        },
        ticket: {
            type: Object,
            default: () => ({})
        },
        ticketCreator: {
            type: Object,
            default: () => ({})
        },
    },
    data: () => ({
        wizardAnswers: [] as WizardAnswer
    }),
    mounted() {
        console.log("Ticket", this.ticket);
        console.log("Ticket Creator", this.ticketCreator);
        try {
            this.fetchAnswers();
        } catch (error) {
            console.error("Error fetching wizard answers", error);
        }
    },
    methods: {
        fetchAnswers(): Promise<WizardAnswer> {
            return new Promise((resolve, reject) => {
                fetch(`/wizard-questions/${this.ticket.lead.sectors[0].id}/${this.ticket.lead.id}`)
                    .then(response => response.json())
                    .then(data => {
                        resolve(data);
                        console.log("[Resolved] Wizard Answers", data);
                        this.wizardAnswers = data;
                    })
                    .catch(error => {
                        reject(error);
                        console.error("[Rejected] Wizard Answers", error);
                    });
            });
        }
    }

};
</script>