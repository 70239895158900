<template>
    <div class="flex justify-start text-2xl gap-4">
        <button class="py-2 px-3 border border-gray-300 rounded-md hover:bg-gray-100 transition"
            :class="tabStatusClasses(Tabs.Phone)" @click.prevent="setTab(Tabs.Phone)">
            <font-awesome-icon :icon="'phone'"></font-awesome-icon>
        </button>

        <button class="py-2 px-3 border border-gray-300 rounded-md hover:bg-gray-100 transition"
            :class="tabStatusClasses(Tabs.Email)" @click.prevent="setTab(Tabs.Email)">
            <font-awesome-icon :icon="'envelope'"></font-awesome-icon>
        </button>

        <button class="py-2 px-3 border border-gray-300 rounded-md hover:bg-gray-100 transition"
            :class="tabStatusClasses(Tabs.Calendar), { 'text-gray-400': isNull(vendors) }" :disabled="isNull(vendors)"
            @click.prevent="setTab(Tabs.Calendar)">
            <font-awesome-icon :icon="'calendar'"></font-awesome-icon>
        </button>

        <button class="py-2 px-3 border border-gray-300 rounded-md hover:bg-gray-100 transition"
            :class="tabStatusClasses(Tabs.Custom)" @click.prevent="setTab(Tabs.Custom)">
            <font-awesome-icon :icon="'plus-square'"></font-awesome-icon>
        </button>
    </div>

    <div class="w-full min-h-64 bg-white border border-gray-300 rounded-md mt-2 p-4 shadow-lg">
        <div class="flex gap-4" v-if="isActiveTab(Tabs.Phone)">
            <div>
                <input id="phone_success" type="radio" name="type" class="peer hidden" v-model="type"
                    value="phone_success" @change="setDefaultMessage()">
                <label
                    class="flex flex-col items-center cursor-pointer p-2 border border-gray-300 rounded-md peer-checked:bg-gray-100 transition"
                    for="phone_success">
                    <font-awesome-icon class="text-xl" :icon="'phone-volume'"></font-awesome-icon>
                    <span class="text-sm">Erfolgreich</span>
                </label>
            </div>

            <div>
                <input id="phone_fail" type="radio" name="type" class="peer hidden" v-model="type" value="phone_fail"
                    @change="setDefaultMessage()">
                <label
                    class="flex flex-col items-center cursor-pointer p-2 border border-gray-300 rounded-md peer-checked:bg-gray-100 transition"
                    for="phone_fail">
                    <font-awesome-icon class="text-xl" :icon="'phone-slash'"></font-awesome-icon>
                    <span class="text-sm">Erfolglos</span>
                </label>
            </div>
        </div>

        <div class="flex gap-4" v-if="isActiveTab(Tabs.Email)">
            <div>
                <input id="email" type="radio" name="type" class="peer hidden" v-model="type" value="email"
                    @change="setDefaultMessage()">
                <label
                    class="flex flex-col items-center cursor-pointer p-2 border border-gray-300 rounded-md peer-checked:bg-gray-100 transition"
                    for="email">
                    <font-awesome-icon class="text-xl" :icon="'envelope'"></font-awesome-icon>
                    <span class="text-sm">Versendet</span>
                </label>
            </div>

            <div>
                <input id="email_success" type="radio" name="type" class="peer hidden" v-model="type"
                    value="email_success" @change="setDefaultMessage()">
                <label
                    class="flex flex-col items-center cursor-pointer p-2 border border-gray-300 rounded-md peer-checked:bg-gray-100 transition"
                    for="email_success">
                    <font-awesome-icon class="text-xl" :icon="'envelope-circle-check'"></font-awesome-icon>
                    <span class="text-sm">Erfolgreich</span>
                </label>
            </div>

            <div>
                <input id="email_fail" type="radio" name="type" class="peer hidden" v-model="type" value="email_fail"
                    @change="setDefaultMessage()">
                <label
                    class="flex flex-col items-center cursor-pointer p-2 border border-gray-300 rounded-md peer-checked:bg-gray-100 transition"
                    for="email_fail">
                    <font-awesome-icon class="text-xl" :icon="'times'"></font-awesome-icon>
                    <span class="text-sm">Erfolglos</span>
                </label>
            </div>
        </div>

        <div v-if="isActiveTab(Tabs.Custom)" class="lmt-form-group-sm mb-3">
            <label for="custom_type">Titel</label>
            <input id="custom_type" type="text" data-rules="required"
                class="form-control w-full border border-gray-300 rounded-md px-3 py-2" value="Sonstiges" disabled>
        </div>

        <div class="flex gap-4" v-if="isActiveTab(Tabs.Calendar)">
            <div>
                <input id="appointment_follow_up" type="radio" name="type" class="peer hidden" v-model="type"
                    value="appointment_follow_up" @change="setDefaultMessage()">
                <label
                    class="flex flex-col items-center cursor-pointer p-2 border border-gray-300 rounded-md transition"
                    for="appointment_follow_up">
                    <font-awesome-icon class="text-xl" :icon="'calendar'"></font-awesome-icon>
                    <span class="text-sm">Folgetermin</span>
                </label>
            </div>

            <div>
                <input id="appointment_onboarding" type="radio" name="type" class="peer hidden" v-model="type"
                    value="appointment_onboarding" @change="setDefaultMessage()">
                <label
                    class="flex flex-col items-center cursor-pointer p-2 border border-gray-300 rounded-md transition"
                    for="appointment_onboarding">
                    <font-awesome-icon class="text-xl" :icon="'calendar-plus'"></font-awesome-icon>
                    <span class="text-sm">Onboarding</span>
                </label>
            </div>

            <div class="grid grid-cols-3 gap-4 mt-3">
                <div class="lmt-form-group-sm">
                    <label for="date">Datum</label>
                    <input id="custom_type" type="date"
                        class="form-control w-full border border-gray-300 rounded-md px-3 py-2" v-model="calendarDate"
                        @change="setCalendarMessage()">
                </div>

                <div class="lmt-form-group-sm">
                    <label for="time">Zeit</label>
                    <input id="custom_type" type="time"
                        class="form-control w-full border border-gray-300 rounded-md px-3 py-2" v-model="calendarTime"
                        @change="setCalendarMessage()">
                </div>

                <div class="lmt-form-group-sm">
                    <label for="software">Software</label>
                    <select id="software" class="form-control w-full border border-grey-300 rounded-md"
                        v-model="vendorId" @change="setCalendarMessage()">
                        <option v-for="vendor in vendors" :value="vendor.id">{{ vendor.name }}</option>
                    </select>
                </div>
            </div>
        </div>

        <textarea name="entry" id="entry" class="w-full border border-gray-300 rounded-md px-3 py-2 mt-4" rows="4"
            v-model="entry" placeholder="Enter your message here..."></textarea>

        <button class="bg-green-500 text-white py-2 px-4 rounded-md mt-4 hover:bg-green-600 transition"
            @click.prevent="createEntry()" :disabled="hasErrors">Erstellen</button>
    </div>

    <div class="mt-6 p-4" v-if="!isUndefined(this.entries)">
        <div class="relative flex flex-col gap-4 p-2" v-for="(entry, index) in entries" :key="index">
            <!-- Remove the initial div containing the line and icon to reposition the icon -->
            <template v-if="isNull(entry.id)">
                <input type="hidden" :name="`protocol_entries[${index}][type]`" :value="entry.type">
                <input type="hidden" :name="`protocol_entries[${index}][entry]`" :value="entry.entry">
                <input type="hidden" :name="`protocol_entries[${index}][username]`" :value="entry.username">
                <input type="hidden" :name="`protocol_entries[${index}][timestamp]`" :value="entry.timestamp">
                <input type="hidden" :name="`protocol_entries[${index}][date]`" :value="entry.date">
                <input type="hidden" :name="`protocol_entries[${index}][time]`" :value="entry.time">
                <input type="hidden" :name="`protocol_entries[${index}][vendor_id]`" :value="entry.vendorId">
            </template>
            <div class="bg-gray-50 p-4 rounded-md shadow-md text-sm">
                <div class="flex items-center space-x-2"> <!-- Use flex layout for alignment -->
                    <font-awesome-icon class="p-2 bg-gray-100 rounded-full"
                        :icon="getIconName(entry.type)"></font-awesome-icon>
                    <strong>{{ dateTimeFormat(entry.timestamp) }} Uhr - {{ entry.user?.full_name ??
                        entry.username }}</strong>
                </div>
                <span>{{ entry.entry }}</span>
            </div>
        </div>
    </div>

</template>


<script lang="ts">

import { defineComponent } from "vue";
import { Store, useStore } from "vuex";
import { key, RootStoreInterface } from "../../Store";
import { LeadMap } from "../../Store/Lead";
import { Lead, ProtocolEntry, Sector, Vendor } from "../../typings/types";

enum Tabs {
    Phone,
    Email,
    Calendar,
    Custom
}

enum Icons {
    phone_success = 'phone-volume',
    phone_fail = 'phone-slash',
    email = 'envelope',
    email_success = 'envelope-circle-check',
    email_fail = 'times',
    data_transfer_accepted = 'check',
    data_transfer_withdrawn = 'times',
    custom = 'plus-square',
    closed = 'handshake',
    appointment_follow_up = 'calendar',
    appointment_onboarding = 'calendar-plus',
}

enum ProtocolEntryTypes {
    phone_success = 'phone_success',
    phone_fail = 'phone_fail',
    email = 'email',
    email_success = 'email_success',
    email_fail = 'email_fail',
    data_transfer_accepted = 'data_transfer_accepted',
    data_transfer_withdrawn = 'data_transfer_withdrawn',
    custom = 'custom',
    closed = 'closed',
    appointment_onboarding = 'appointment_onboarding',
    appointment_follow_up = 'appointment_follow_up',
}

type ProtocolData = {
    Tabs: Object,
    store: Store<RootStoreInterface>,
    activeTab: Tabs,
    entries: Array<ProtocolEntry>
    entry: string,
    calendarDate: string,
    calendarTime: string,
    vendorId: string,
    type: ProtocolEntryTypes,
    errors: Array<string>,
}

export default defineComponent({
    props: {
        initialLead: Object as () => Lead,
        userName: String
    },

    data(): ProtocolData {
        return {
            Tabs,
            store: useStore(key),
            activeTab: Tabs.Phone,
            entries: this.initialLead.protocol_entries,
            type: null,
            entry: '',
            calendarDate: null,
            calendarTime: null,
            vendorId: null,
            errors: [],
        }
    },

    computed: {
        hasErrors: function () {
            if (this.activeTab === Tabs.Calendar) {
                return !this.isFieldValid(this.calendarDate) || !this.isFieldValid(this.calendarTime) || !this.isFieldValid(this.vendorId)
            }

            return !this.isFieldValid(this.type) || !this.isFieldValid(this.entry)
        },

        isDataTransferAccepted() {
            return this.store.getters[LeadMap.getters.getIsDataTransferAccepted]
        },

        vendors: function (): Array<Vendor> {
            let sectors = this.store.getters[LeadMap.getters.getSectors];

            if (this.isEmpty(sectors)) {
                return sectors
            }

            return sectors.flatMap((sector: Sector) => {
                return sector.vendors
            })
        }
    },

    watch: {
        isDataTransferAccepted() {
            this.toggleIsDataTransferAcceptedEntry()
        }
    },

    methods: {
        setTab(tab: Tabs) {
            this.type = null
            this.entry = ''

            if (tab === Tabs.Custom) {
                this.type = ProtocolEntryTypes.custom
            }

            this.activeTab = tab
        },

        isActiveTab(tab: Tabs) {
            return this.activeTab === tab
        },

        tabStatusClasses: function (tab: Tabs) {
            if (this.isActiveTab(tab)) {
                return 'bg-gray-50 border-b-0'
            }

            return 'bg-gray-200 border-gray-300'
        },

        getIconName(type: Icons): string {
            return Icons[type]
        },

        getDefaultMessage(type: ProtocolEntryTypes, interpolations: Object = {}) {
            const message = this.$t(`protocolEntry.${type}`, interpolations)

            if (message === `protocolEntry.${type}`) {
                return
            }

            return message
        },

        setCalendarMessage() {
            this.entry = this.getDefaultMessage(this.type, {
                leadName: this.initialLead.user?.full_name ?? '',
                leadCompanyName: this.initialLead.company_name ?? '',
                softwareName: this.vendors.find((vendor: Vendor) => vendor.id === this.vendorId)?.software_name ?? '',
                date: this.dateFormat(this.calendarDate),
                time: this.calendarTime
            })
        },

        setDefaultMessage() {
            const defaultMessage = this.getDefaultMessage(this.type)
            this.entry = defaultMessage ?? ''
        },

        isEntryValid() {
            return !this.hasErrors
        },

        isFieldValid(field: string) {
            return !this.isEmpty(field)
        },

        createEntry(type: ProtocolEntryTypes | null = null, entry: string | null = null) {
            if ((this.isNull(type) || this.isNull(entry)) && this.hasErrors) {
                return
            }

            this.entries.unshift({
                type: type ?? this.type,
                timestamp: (new Date()).toISOString(),
                username: this.userName,
                entry: entry ?? this.entry,
                vendorId: this.vendorId,
                date: this.calendarDate,
                time: this.calendarTime,
            })

            this.type = null
            this.entry = ''
            this.calendarDate = null
            this.calendarTime = null
            this.vendorId = null
        },

        toggleIsDataTransferAcceptedEntry() {
            if (!this.isDataTransferAccepted) {
                this.entries = this.entries?.filter(entry => {
                    return !(this.isUndefined(entry.id) && entry.type === ProtocolEntryTypes.data_transfer_accepted)
                })
            }

            if (this.isDataTransferAccepted) {
                this.entries = this.entries?.filter(entry => {
                    return !(this.isUndefined(entry.id) && entry.type === ProtocolEntryTypes.data_transfer_withdrawn)
                })
            }

            if (this.isDataTransferAccepted === this.initialLead.data_transfer_accepted) {
                return
            }

            if (this.isUndefined(this.initialLead.data_transfer_accepted) && !this.isDataTransferAccepted) {
                return
            }

            let type = ProtocolEntryTypes.data_transfer_accepted

            if (!this.isDataTransferAccepted) {
                type = ProtocolEntryTypes.data_transfer_withdrawn
            }

            const now = new Date()
            const message = this.getDefaultMessage(type, {
                userName: this.userName,
                leadName: this.initialLead.user?.full_name ?? '',
                leadCompanyName: this.initialLead.company_name ?? '',
                date: this.dateFormat(now),
                time: this.timeFormat(now),
            })

            this.createEntry(type, message)
        }
    }
})

</script>
