<template>
    <Modal v-if="isModalVisible" @close-modal="toggleModal" title="Passed props" :show="isModalVisible"
        :message="`Ticket: ${ticket.id}, Role: ${userRole}, Team Member: ${isTeamMember}`" :ticket="ticket"
        :ticketCreator="ticketCreator" />
    <!-- <div class="">
        <div class="">
            <button @click="toggleModal" class="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600">
                Open Modal
            </button>
        </div>
    </div> -->
    <!-- add icons for return, view and customer_closing -->
    <div class="flex justify-center space-x-4">
        <font-awesome-icon :icon="['fas', 'reply']" @click="handleAction('return')"
            class="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600" title="Lead Zurückgeben"
            disabled></font-awesome-icon>
        <font-awesome-icon :icon="['fas', 'eye']" @click="handleAction('view')"
            class="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600" title="Lead Ansehen"
            data-action="view">
        </font-awesome-icon>


        <font-awesome-icon :icon="['fa', 'handshake']" @click="handleAction('customer_closing')"
            class="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600" title="Kundenabschluss bestätigen"
            disabled></font-awesome-icon>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import Modal from '@/components/Utilities/Modal.vue';

export default defineComponent({
    name: 'QualifierActions',
    components: {
        Modal
    },
    data() {
        return {
            isModalVisible: false
        }
    },
    props: {
        ticket: {
            type: Object,
            required: true
        },
        userRole: {
            type: String,
            required: true
        },
        isTeamMember: {
            type: Boolean,
            required: true
        },
        ticketCreator: {
            type: Object,
            required: true
        }
    },
    mounted: function () {
        console.log("ticket", this.ticket);
        console.log("userRole", this.userRole);
        console.log("isTeamMember", this.isTeamMember);
        console.log("ticketCreator", this.ticketCreator);
    },
    methods: {
        handleAction(action: string) {
            console.log("Action", action);
            if (action === 'view') {
                this.isModalVisible = true;
            }
        },

        toggleModal() {
            this.isModalVisible = !this.isModalVisible;
        }

    }
});
</script>