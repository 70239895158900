<template>
    <table class="min-w-1/2 mx-auto bg-white shadow-md rounded my-6">
        <thead>
            <tr>
                <!-- Table Headers -->
                <th
                    class="px-3 py-3 border-b-2 border-gray-300 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                    ID</th>
                <th
                    class="px-3 py-3 border-b-2 border-gray-300 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                    Date</th>
                <th
                    class="px-3 py-3 border-b-2 border-gray-300 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                    Name</th>
                <th
                    class="px-3 py-3 border-b-2 border-gray-300 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                    Email</th>
                <th
                    class="px-3 py-3 border-b-2 border-gray-300 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                    Lead Qualifier</th>
                <th
                    class="px-3 py-3 border-b-2 border-gray-300 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                    Branche</th>
                <th
                    class="px-3 py-3 border-b-2 border-gray-300 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                    Origin</th>
                <th
                    class="px-3 py-3 border-b-2 border-gray-300 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                    Status</th>
                <th
                    class="px-3 py-3 border-b-2 border-gray-300 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                    Verlauf</th>
                <th
                    class="px-3 py-3 border-b-2 border-gray-300 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                    Aktionen</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="lead in leads" :key="lead.id" :class="{
                'bg-gradient-to-r from-green-200 to-red-200': lead.state === 'new' && lead.isUrgent,
                'bg-green-100': lead.state === 'new' && !lead.isUrgent,
                'bg-red-100': lead.state !== 'new' && lead.isUrgent
            }">
                <!-- Lead ID -->
                <td class="px-3 py-4 whitespace-no-wrap border-b border-gray-500">
                    <div class="text-sm font-medium text-gray-900">{{ lead.id }}</div>
                </td>
                <!-- Lead Date -->
                <td class="px-3 py-4 whitespace-no-wrap border-b border-gray-500">
                    <div class="text-sm text-gray-900">
                        {{ new Intl.DateTimeFormat('en-GB').format(new Date(lead.created_at)) }}
                    </div>
                </td>
                <!-- Lead Name -->
                <td class="px-3 py-4 whitespace-no-wrap border-b border-gray-500">
                    <div class="text-sm text-gray-900">{{ lead.user ? `${lead.user.first_name} ${lead.user.last_name}` :
                        'Nutzer nicht verfügbar' }}</div>
                </td>
                <!-- Lead Email -->
                <td class="px-3 py-4 whitespace-no-wrap border-b border-gray-500">
                    <div class="text-sm text-gray-900">{{ lead.user ? lead.user.email : 'N/A' }}</div>
                </td>
                <!-- Lead Qualifier -->
                <td class="px-3 py-4 whitespace-no-wrap border-b border-gray-500">
                    <div class="text-sm text-gray-900">{{ lead.lead_qualifier }}</div>
                </td>
                <!-- Lead Branche -->
                <td class="px-3 py-4 whitespace-no-wrap border-b border-gray-500">
                    <div class="text-sm text-gray-900">{{ lead.sectors ? lead.sectors.map(sector => sector.name)
                        .join('/ ') : 'k. A.' }}</div>
                </td>
                <!-- Lead Origin -->
                <td class="px-3 py-4 whitespace-no-wrap border-b border-gray-500">
                    <div class="text-sm text-gray-900">{{ lead.origin }}</div>
                </td>
                <!-- Lead Status and Ticket States -->
                <td class="px-3 py-4 whitespace-no-wrap border-b border-gray-500">
                    <div class="text-sm text-gray-900">{{ lead.stateNicename }}</div>
                    <div class="ticket-states">
                        <!-- Loop through tickets -->
                        <div v-if="lead.tickets.length > 0">
                            <label v-for="ticket in lead.tickets" :key="ticket.state" class="ticket-state"
                                v-bind:data-count="getTicketCount(lead, ticket.state)">
                                >
                                {{ ticket.state === 'pending' ? 'Pending' : ticket.state === 'accepted' ? 'Accepted' :
                                    ticket.state === 'returned' ? 'Returned' : 'Customer Closing' }}
                            </label>
                        </div>
                    </div>
                </td>
                <!-- Lead Protocol Entries -->
                <td class="px-3 py-4 whitespace-no-wrap border-b border-gray-500">
                    <div v-for="entry in lead.protocol_entries" :key="entry.id" class="protocol-entry"
                        :data-type="entry.type">
                        <!-- Render protocol entries based on type -->
                    </div>
                </td>
                <!-- Lead Actions -->
                <td class="px-3 py-4 whitespace-no-wrap border-b border-gray-500 space-x-4">
                    <a v-if="lead.state === 'closed'" :data-item-id="lead.id"
                        :data-transfer-accepted="lead.data_transfer_accepted"></a>
                    <a v-else class="list-action" :href="'/leads/' + lead.id + '/edit'">
                        <font-awesome-icon :icon="['fas', 'edit']" />
                    </a>
                    <a :data-item-id="lead.id" :data-transfer-accepted="lead.data_transfer_accepted">
                        <font-awesome-icon :icon="['fas', 'circle-xmark']" />
                    </a>
                    <a @click="deleteLead(lead.id)">
                        <font-awesome-icon :icon="['fas', 'trash-alt']" />
                    </a>
                </td>
            </tr>
        </tbody>
    </table>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

interface Ticket {
    state: string;
    viewed_at: string | null;
    allocated_at: string;
}

interface User {
    first_name: string;
    last_name: string;
    email: string;
}

interface Lead {
    id: number;
    created_at: string;
    user: User | null;
    lead_qualifier: string;
    sectors: Array<{ name: string }> | null;
    origin: string;
    state: string;
    stateNicename: string;
    isUrgent: boolean;
    tickets: Ticket[];
    protocol_entries: Array<{ id: number; type: string }>;
}

export default defineComponent({
    props: {
        leads: {
            type: Array as () => Lead[],
            required: true,
        },
    },
    data() {
        return {
            pendingTickets: 0,
            acceptedTickets: 0,
            returnedTickets: 0,
            customerClosingTickets: 0,
            unviewedPendingTickets: 0,
            unviewedAcceptedTickets: 0,
            unviewedReturnedTickets: 0,
            unviewedCustomerClosingTickets: 0,
            pendingTicketsOverdue: false,
            acceptedTicketsOverdue: false,
            returnedTicketsOverdue: false,
            customerClosingTicketsOverdue: false,
        };
    },
    mounted() {
        console.log(this.leads);
    },
    methods: {
        getTicketCount(lead: Lead) {
            // for each ticket, increment the respective counter based on the ticket state
            for (const ticket of lead.tickets) {
                switch (ticket.state) {
                    case 'pending':
                        this.pendingTickets++;
                        break;
                    case 'accepted':
                        this.acceptedTickets++;
                        break;
                    case 'returned':
                        this.returnedTickets++;
                        break;
                    case 'customer_closing':
                        this.customerClosingTickets++;
                        break;
                }

                // if ticket.viewed_at is null, and ticket.allocated_at was more than 24 hours ago, increment the respective unviewed counter
                if (!ticket.viewed_at) {
                    if (this.isOverdue(ticket)) {
                        switch (ticket.state) {
                            case 'pending':
                                this.unviewedPendingTickets++;
                                break;
                            case 'accepted':
                                this.unviewedAcceptedTickets++;
                                break;
                            case 'returned':
                                this.unviewedReturnedTickets++;
                                break;
                            case 'customer_closing':
                                this.unviewedCustomerClosingTickets++;
                                break;
                        }
                    }
                }

                // if pending tickets are equal to unviewed pending tickets, set pendingTicketsOverdue to true
                if (this.pendingTickets === this.unviewedPendingTickets) {
                    this.pendingTicketsOverdue = true;
                }
                if (this.acceptedTickets === this.unviewedAcceptedTickets) {
                    this.acceptedTicketsOverdue = true;
                }
                if (this.returnedTickets === this.unviewedReturnedTickets) {
                    this.returnedTicketsOverdue = true;
                }
                if (this.customerClosingTickets === this.unviewedCustomerClosingTickets) {
                    this.customerClosingTicketsOverdue = true;
                }

            }
        },
        isOverdue(ticket: Ticket) {
            if (!ticket.viewed_at) {
                const currentDate = new Date().getTime();
                const allocatedAt = new Date(ticket.allocated_at).getTime();
                const hoursPassed = (currentDate - allocatedAt) / (1000 * 60 * 60);
                return hoursPassed >= 24; // Overdue if more than 24 hours
            }
            return false;
        },
        deleteLead(id: number) {
            // Create confirmation modal with Tailwind CSS
            const modal = document.createElement('div');
            modal.classList.add('fixed', 'inset-0', 'bg-black', 'bg-opacity-50', 'flex', 'items-center', 'justify-center');
            modal.innerHTML = `
          <div class="bg-white p-6 rounded-xl shadow-lg max-w-md mx-auto">
            <p class="text-lg font-semibold text-gray-700 mb-4">Are you sure you want to delete this lead?</p>
            <div class="flex justify-end space-x-4">
              <button 
                class="bg-red-600 hover:bg-red-700 text-white px-3 py-2 rounded-lg shadow-md transition duration-200" 
                onclick="window.location.href='/leads/${id}/delete'">
                Yes
              </button>
              <button 
                class="bg-gray-600 hover:bg-gray-700 text-white px-3 py-2 rounded-lg shadow-md transition duration-200" 
                onclick="document.body.removeChild(this.parentElement.parentElement.parentElement)">
                No
              </button>
            </div>
          </div>
        `;
            document.body.appendChild(modal);
        },
    },
});
</script>